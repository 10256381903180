.devops-layout-content {
  width: 100%;
  margin-left: 10px;
  padding: 10px;
  background: #fff;
}



.find-icon-base {
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 5px;
  border-radius: 15px;
}

.find-status-best {
  background: #468847;
  border-color: #dff0d8;
}
.find-status-normal {
  background: #c09853;
  border-color: #fcf8e3;
}
.find-status-bad {
  background: #b94a48;
  border-color: #f2dede;
}
.find-img-map {
  position: relative;
  display: flex;
  justify-content: center;
}
.find-cls-device {
  position: absolute;
}
.find-cls-no-floor-container {
  margin: 10px 0 20px 50px;
  .find-cls-no-floor-title {
    font-size: 16px;
  }
  .find-cls-no-floor-device {
    margin-top: 5px;
  }
}
