@import "~antd/dist/antd.css";
@import "~devops-fe/dist/static/index.css";


.system-logo {
  width: 120px;
  height: 35px;
  margin: 12px 24px 20px 0;
  background: url(https://s.momocdn.com/w/u/img/2017/03/28/1490673490218-%E5%AE%98%E7%BD%91logo.png) no-repeat;
  background-size: cover;
}

/* 表单的样式 */
.form-item-input {
  width: 450px;
}
